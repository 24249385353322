<template>
  <b-modal
    id="add-health-declaration-form"
    size="lg"
    title=""
    @ok="handleOk"
    centered
    ref="add-health-declaration-form"
    @show="onReset"
    :cancel-disabled="disableConfirmButtons"
    :ok-disabled="disableConfirmButtons"
    :no-close-on-backdrop="true"
  >
    <b-row>
      <b-col sm="10" offset-sm="1">
        <div class="header">Health Declaration Form</div>
      </b-col>
      <b-col sm="8" offset-sm="2" class="mb-4">
        <div class="instruction">
          <i class="fa fa-exclamation-triangle fa-lg"></i>
          &nbsp;&nbsp;For the health and safety of our community, declaration of
          illness is required. Be sure that the information you'll give is
          accurate and complete. Please get immediate medical attention if you
          have any of the COVID-19 signs.
        </div>
      </b-col>
    </b-row>

    <section class="section">
      <div class="container">
        <div class="columns">
          <div class="column is-8 is-offset-2">
            <horizontal-stepper
              :steps="demoSteps"
              :top-buttons="showTopButtons"
              @completed-step="completeStep"
              @active-step="isStepActive"
              @stepper-finished="saveForm"
            >
            </horizontal-stepper>
          </div>
        </div>
      </div>
    </section>

    <template #modal-footer>
      <div class="w-100">
        <b-button
          variant="primary"
          hidden
          class="float-right"
          @click="show = false"
        >
          Save
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import EventBus from "@/shared/event-bus";
import HorizontalStepper from "vue-stepper";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import config from "@/config/env-constants";

export default {
  name: "HealthDeclarationForm",
  components: {
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
    HorizontalStepper,
    Loading,
  },
  props: {},
  data() {
    return {
      form: {},
      showTopButtons: true,
      demoSteps: [
        {
          icon: "check",
          name: "first",
          title: "Temperature",
          subtitle: "Step 1",
          component: Step1,
          completed: false,
        },
        {
          icon: "check",
          name: "second",
          title: "Transportation",
          subtitle: "Step 2",
          component: Step2,
          completed: false,
        },
        {
          icon: "check",
          name: "third",
          title: "Visit",
          subtitle: "Step 3",
          component: Step3,
          completed: false,
        },
        {
          icon: "check",
          name: "fourth",
          title: "Symptoms",
          subtitle: "Step 4",
          component: Step4,
          completed: false,
        },
        {
          icon: "check",
          name: "fifth",
          title: "Questions",
          subtitle: "Step 5",
          component: Step5,
          completed: false,
        },
      ],

      formSource: "",

      // Check for loader
      isLoading: false,
    };
  },
  computed: {
    disableConfirmButtons() {
      return this.isLoading;
    },
  },
  mounted() {
    let vm = this;

    EventBus.$on("update-form-source", function (source) {
      vm.formSource = source;
    });

    // Update the form content from step 1 to step 5
    EventBus.$on("form-update", function (form) {
      vm.form = Object.assign(vm.form, form);
    });
  },
  methods: {
    // Executed when @completed-step event is triggered
    completeStep(payload) {
      this.demoSteps.forEach((step) => {
        if (step.name === payload.name) {
          step.completed = true;
        }
      });
    },
    // Executed when @active-step event is triggered
    isStepActive(payload) {
      this.demoSteps.forEach((step) => {
        if (step.name === payload.name) {
          if (step.completed === true) {
            step.completed = false;
          }

          EventBus.$emit(`reload-content-${payload.index}`, { value: true });
        }
      });
    },
    // Executed when @stepper-finished event is triggered
    async saveForm() {
      console.log(this.form);
      this.$refs["add-health-declaration-form"].hide();

      if (this.formSource === config.formSource.CHECK_IN) {
        // proceed with checkin
        this.$emit("proceed-checkin", { value: this.form });
      } else if (this.formSource === config.formSource.REPORT) {
        // proceed with health report
        this.$emit("proceed-report-health", { value: this.form });
      }
    },
    handleOk(evt) {
      // Prevent modal from closing
      evt.preventDefault();
    },
    onReset() {
      this.form = {};
    },
    beforeDestroy() {
      EventBus.$off("reload-content-0");
      EventBus.$off("reload-content-1");
      EventBus.$off("reload-content-2");
      EventBus.$off("reload-content-3");
      EventBus.$off("reload-content-4");
    },
  },
};
</script>

<style scoped>
.header {
  font-size: 24px;
  margin-bottom: 0px;
  text-align: center;
  font-weight: bold;
}

.instruction {
  padding-top: 10px;
  font-size: 12px;
  text-align: center;
  vertical-align: baseline;
  color: #b00020;
}
</style>