<template>
  <div>
    <div v-if="getCurrMainHub">
      <b-card title="STATUS">
        <b-card-text>
          <b-form-group
            label="Company"
            label-size="sm"
            label-class="font-weight-bold pt-0"
          >
            <div class="form-value">
              {{ $store.getters["auth/getDisplayCompany"] }} <br />
            </div>
          </b-form-group>
          <b-form-group
            label="Assigned Hub"
            label-size="sm"
            label-class="font-weight-bold pt-0"
          >
            <div class="form-value">
              {{ getCurrMainHub.name }} <br />
              <span class="hub-code">({{ getCurrMainHub.qrcode }})</span>
            </div>
          </b-form-group>

          <b-form-group
            label="Need help with your health condition?"
            label-size="sm"
            label-class="font-weight-bold pt-0"
          >
            <b-button
              class="btn"
              variant="info"
              :disabled="isReporting"
              @click="updateFormSource('Report')"
              v-b-modal.add-health-declaration-form
            >
              <span v-if="isReporting">
                Submitting Report <i class="fas fa-spinner fa-spin"></i>
              </span>
              <span v-else> Report </span>
            </b-button>
          </b-form-group>
        </b-card-text>
      </b-card>

      <b-card title="TODAY'S WORKLOG">
        <b-card-text>
          <b-form-group
            label="Check-In"
            label-size="sm"
            label-class="font-weight-bold pt-0"
          >
            <div class="form-value">
              {{ getCurrWorkLog.timein ? getCurrWorkLog.timein : "--" }}
            </div>
          </b-form-group>

          <b-form-group
            label="Check-Out"
            label-size="sm"
            label-class="font-weight-bold pt-0"
          >
            <div class="form-value">
              {{ getCurrWorkLog.timeout ? getCurrWorkLog.timeout : "--" }}
            </div>
          </b-form-group>

          <b-form-group
            label="Duration"
            label-size="sm"
            label-class="font-weight-bold pt-0"
          >
            <div class="form-value">
              {{ getCurrWorkLog.totalHours ? getCurrWorkLog.totalHours : 0 }}
            </div>
          </b-form-group>

          <div class="row" v-if="getCurrMainHub.id">
            <div class="col-md-12">
              <b-button
                v-if="!getCurrWorkLog._timein"
                class="btn"
                variant="primary"
                :disabled="isCheckingIn"
                @click="updateFormSource('Check-In')"
                v-b-modal.add-health-declaration-form
              >
                <span v-if="isCheckingIn">
                  Checking In <i class="fas fa-spinner fa-spin"></i>
                </span>
                <span v-else> Check In </span>
              </b-button>

              <b-button
                v-if="getCurrWorkLog._timein"
                class="btn"
                variant="danger"
                :disabled="isCheckingOut"
                @click="checkOut()"
              >
                <span v-if="isCheckingOut">
                  Checking Out <i class="fas fa-spinner fa-spin"></i>
                </span>
                <span v-else> Check Out </span>
              </b-button>
            </div>
          </div>
        </b-card-text>
      </b-card>
    </div>

    <HealthDeclarationForm
      @proceed-checkin="checkIn"
      @proceed-report-health="reportHealth"
    />
  </div>
</template>

<script>
import HealthDeclarationForm from "../forms/health/HealthDeclarationForm";
import { mapGetters } from "vuex";
import moment from "moment";
import DateUtil from "../../utils/date";
import UserWorkLogAPI from "../../api/userWorkLog";
import _ from "lodash";
import EventBus from "@/shared/event-bus";

export default {
  name: "check-in-status",
  components: {
    HealthDeclarationForm,
  },
  data() {
    return {
      isCheckingIn: false,
      isCheckingOut: false,
      isReporting: false,
      currentDisplayDate: moment().format("MMMM D, YYYY h:mm A"),
      currentDate: DateUtil.getDateFormatForField(new Date().getTime()),
      location: null,
      gettingLocation: false,
      error: null,
    };
  },
  computed: mapGetters("worklog", ["getCurrWorkLog", "getCurrMainHub"]),
  created() {
    this.fetchLocation();
  },
  methods: {
    fetchLocation() {
      if (!("geolocation" in navigator)) {
        this.error = "Geolocation is not available.";
        return;
      }

      this.gettingLocation = true;

      // get position
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          this.gettingLocation = false;
          this.location = pos;
        },
        (err) => {
          this.gettingLocation = false;
          this.error = err.message;
        }
      );
    },
    updateFormSource(source){
      EventBus.$emit(`update-form-source`, source);
    },
    createScanLog() {
      const user = this.$store.getters["auth/getCurrentUser"];
      const params = {
        userId: user.id,
        userName: `${user.firstName} ${user.lastName}`,
        userCompany: user.company,
        userCompanyId: user.companyId,
        hubId: this.getCurrMainHub.id,
        hubName: this.getCurrMainHub.name,
        hubCode: this.getCurrMainHub.qrcode,
        hubCompany: this.getCurrMainHub.company,
        hubCompanyId: this.getCurrMainHub.companyId,
        userLguId: user.lguId,
        createdBy: user.email,
        dateCreated: new Date().getTime(),
      };

      if (!_.isEmpty(this.location) && this.location.coords) {
        params.latitude = this.location.coords.latitude;
        params.longitude = this.location.coords.longitude;
      }

      return params;
    },
    createUserFormLog(form) {
      const user = this.$store.getters["auth/getCurrentUser"];
      let params = {
        userId: user.id,
        userName: `${user.firstName} ${user.lastName}`,
        userCompany: user.company,
        userCompanyId: user.companyId,
        hubId: this.getCurrMainHub.id,
        hubName: this.getCurrMainHub.name,
        hubCode: this.getCurrMainHub.qrcode,
        hubCompany: this.getCurrMainHub.company,
        hubCompanyId: this.getCurrMainHub.companyId,
        userLguId: user.lguId,
        createdBy: user.email,
        dateCreated: new Date().getTime(),
        formType: "Health Declaration Form",
      };
      params.form = form;
      return params;
    },
    async reportHealth(data) {
      try {
        this.isReporting = true;

        let params = {};

        if (!_.isEmpty(data) && !_.isEmpty(data.value)) {
          params.userFormLog = this.createUserFormLog(data.value);
        }

        await UserWorkLogAPI.checkin(params);
        await this.$store.dispatch("worklog/fetchCurrUserFormLog");
        this.$toast.success(
          "Your health condition has been submitted.",
          "Success!"
        );
        this.isReporting = false;
      } catch (error) {
        console.error("Error: " + JSON.stringify(error));
        this.$toast.error(
          "There was a problem on your health report. Please try again later.",
          "Oops!"
        );
        this.isReporting = false;
      }
    },
    async checkIn(data) {
      try {
        this.isCheckingIn = true;

        let params = {};

        let scanLog = this.createScanLog();
        scanLog.isCheckIn = true;
        params.scanLog = scanLog;

        if (!_.isEmpty(data) && !_.isEmpty(data.value)) {
          params.userFormLog = this.createUserFormLog(data.value);
        }

        await UserWorkLogAPI.checkin(params);
        await this.$store.dispatch("worklog/fetchAssignedHubs");
        this.$toast.success("Your check-in has been processed.", "Success!");
        this.isCheckingIn = false;
      } catch (error) {
        console.error("Error: " + JSON.stringify(error));
        this.$toast.error(
          "There was a problem on your check-in. Please try again later.",
          "Oops!"
        );
        this.isCheckingIn = false;
      }
    },
    async checkOut(data) {
      try {
        this.isCheckingOut = true;

        let params = {};

        let scanLog = this.createScanLog();
        scanLog.isCheckIn = false;
        params.scanLog = scanLog;

        if (!_.isEmpty(data) && !_.isEmpty(data.value)) {
          params.userFormLog = this.createUserFormLog(data.value);
        }

        await UserWorkLogAPI.checkin(params);
        await this.$store.dispatch("worklog/fetchAssignedHubs");
        this.$toast.success("Your check-out has been processed", "Success!");
        this.isCheckingOut = false;
      } catch (error) {
        console.error("Error: " + JSON.stringify(error));
        this.$toast.error(
          "There was a problem on your check-out. Please try again later.",
          "Oops!"
        );
        this.isCheckingOut = false;
      }
    },
  },
};
</script>

<style scoped>
.hub-code {
  font-size: 13px;
}

.form-value {
  font-size: 14px;
}

.btn {
  min-width: 120px;
}
</style>