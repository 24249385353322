<template>
  <b-card class="card-panel" title="">
    <loading
      :active.sync="isLoading"
      loader="spinner"
      color="#20A8D8"
      :is-full-page="false"
    />
    <b-row>
      <b-col sm="6" md="6" lg="4">
        <b-form-group
          label="Date From"
          label-size="sm"
          label-class="font-weight-bold pt-0"
        >
          <b-input id="dateFrom" type="date" v-model="filterBy.dateFrom" />
        </b-form-group>
      </b-col>
      <b-col sm="6" md="6" lg="4">
        <b-form-group
          label="Date To"
          label-size="sm"
          label-class="font-weight-bold pt-0"
        >
          <b-input id="dateTo" type="date" v-model="filterBy.dateTo" />
        </b-form-group>
      </b-col>
      <b-col sm="12" md="12" lg="4" class="mt-4 mb-4 text-sm-left text-md-left">
        <b-button @click="onFilterRequest">
          <i class="fa fa-search" />
        </b-button>
        <b-button class="ml-1" @click="resetFilters">
          <i class="fa fa-sync" />
        </b-button>
      </b-col>
    </b-row>

    <b-row no-gutters>
      <b-col sm="6" md="6" class="text-md-left mb-2">
        <base-dropdown position="right">
          <base-button slot="title" type="secondary" class="dropdown-toggle"
            >Show {{ perPage }} / Page</base-button
          >
          <b-dropdown-item
            v-for="(option, index) in pageOptions"
            :key="index"
            @click="perPage = option"
            >{{ option }}</b-dropdown-item
          >
        </base-dropdown>
      </b-col>
      <b-col sm="6" md="6" class="mt-3 text-md-right mb-2">
        <span class="total-display">Total: {{ items.length }}</span>
      </b-col>
    </b-row>

    <b-table
      :items="items"
      :fields="fields"
      selected-variant="primary"
      :current-page="currentPage"
      :per-page="perPage"
      striped
      responsive
      style="min-height: 300px"
    >
      <template v-slot:cell(dateCreated)="row">
        {{ row.item.dateCreated | dateFormat }}
        {{ row.item.dateCreated | timeFormat }}
      </template>

      <template v-slot:cell(actions)="row">
        <b-button
          size="sm"
          v-b-tooltip.hover
          title="Show/Hide Other Details"
          variant="success"
          @click.stop="row.toggleDetails"
          class="mr-1"
        >
          <i class="fa fa-eye-slash" v-if="row.detailsShowing"></i>
          <i class="fa fa-eye" v-else></i>
        </b-button>
      </template>

      <template slot="row-details" slot-scope="row">
        <HealthDeclarationFormDetailsView :row="row" />
      </template>
    </b-table>

    <template v-if="items.length === 0">
      <h6 class="text-center">There are no records to show</h6>
    </template>
    <b-row>
      <b-col md="12" sm="12" class="my-1">
        <b-pagination
          align="center"
          :total-rows="items.length"
          :per-page="perPage"
          v-model="currentPage"
          class="my-0"
        />
      </b-col>
    </b-row>
  </b-card>
</template>


<script>
import HealthDeclarationFormDetailsView from "./HealthDeclarationFormDetailsView";
import { mapGetters } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import DateUtil from "../../utils/date";
import FilterUtil from "../../utils/filter";
import ReportAPI from "@/api/report";

export default {
  name: "HealthDeclarationFormHistory",
  components: {
    HealthDeclarationFormDetailsView,
    Loading,
  },
  data() {
    return {
      isLoading: false,
      items: [],
      fields: [
        { key: "dateCreated", label: "Date Accomplished" },
        { key: "actions", label: "Actions" },
      ],
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15],
      filterBy: {
        user: { id: "" },
        userCompany: { id: "" },
        hub: { id: "" },
        hubCompany: { id: "" },
        dateFrom: null,
        dateTo: null,
        orderBy: "desc",
      },
      currentDate: DateUtil.getDateFormatForField(new Date().getTime()),
    };
  },
  computed: mapGetters("worklog", ["getCurrUserFormLog", "getCurrMainHub"]),
  watch: {
    getCurrUserFormLog: {
      handler() {
        this.resetFilters();
      },
      immediate: true,
    },
  },
  methods: {
    getFilterBy(filterBy) {
      let updatedFilterBy = Object.assign({}, filterBy);
      updatedFilterBy.dateFrom = DateUtil.startDateTimeStamp(
        Date.parse(updatedFilterBy.dateFrom)
      );
      updatedFilterBy.dateTo = DateUtil.endDateTimeStamp(
        Date.parse(updatedFilterBy.dateTo)
      );
      updatedFilterBy.orderBy = "desc";

      return updatedFilterBy;
    },
    async onFilterRequest() {
      try {
        this.isLoading = true;

        let filterBy = FilterUtil.getFilterBy(this.filterBy);
        const { data } = await ReportAPI.getUserFormLogs(filterBy);

        this.items = data.items.sort((a, b) => b._timein - a._timein);

        // hide loading indicator
        this.isLoading = false;
      } catch (error) {
        console.error("Error: " + JSON.stringify(error));
        this.$toast.error(
          "There was a problem fetching health declaration forms.",
          "Oops!"
        );

        // hide loading indicator
        this.isLoading = false;
      }
    },
    async resetFilters() {
      const user = this.$store.getters["auth/getCurrentUser"];
      this.filterBy.user.id = user.id;
      this.filterBy.userCompany.id = user.companyId;
      this.filterBy.hub.id = this.getCurrMainHub.id;
      this.filterBy.hubCompany.id = this.getCurrMainHub.companyId;
      this.filterBy.dateFrom = this.currentDate;
      this.filterBy.dateTo = this.currentDate;

      await this.onFilterRequest();
    },
  },
};
</script>

<style scoped>
.total-display {
  font-weight: bold;
}
</style>
